import React, { useEffect } from "react";

/**
 * Firebase Hosting上で /redirect?sourceUrl=xxx のようにアクセスされたら、
 * そのパラメータを使って別サイトへリダイレクトする想定。
 */
const MyNumberRedirect = (props) => {
    // React Router v6+ のフックを使用してクエリパラメータを取得
    const { mi_code, reserve_date, rtn_url, type } = props;

    useEffect(() => {

        if (!mi_code || !reserve_date || !rtn_url || !type) {
            // 必要なパラメータが足りない場合はエラーとする
            console.log("パラメータが不正です。最初からやり直してください。", mi_code, reserve_date, rtn_url, type);
            alert("パラメータが不正です。最初からやり直してください。");
            return;
        }

        // リダイレクト先を組み立て
        // ここでは仮に "https://kdg-st.onshikaku.org/online-care/index.html" へ飛ばす例
        // 必要に応じて sourceUrl や appId をクエリにつける
        let targetUrl = "";
        if (type === "1") {
            // 予約の場合
            targetUrl = `https://kdg-st.onshikaku.org/online-care/index.html?req_type=1&app_id=${process.env.MYNUMBER_APP_ID}&mi_code=${mi_code}&rtn_url=${rtn_url}&clinic_date=${reserve_date}`;
        } else if (type === "2") {
            // 取消の場合
            targetUrl = `https://kdg-st.onshikaku.org/online-care/withdraw.html?req_type=1&app_id=${process.env.MYNUMBER_APP_ID}&mi_code=${mi_code}&rtn_url=${rtn_url}&clinic_date=${reserve_date}`;
        } else {
            // それ以外の場合はエラーとする
            alert("パラメータが不正です。最初からやり直してください。");
            return;
        }

        // 実際にリダイレクト
        window.location.href = targetUrl;
    }, [mi_code, reserve_date, rtn_url, type]);

    return (
        <div style={{ textAlign: "center", marginTop: "2rem" }}>
            <h3>Redirecting...</h3>
            <p>しばらくお待ちください</p>
        </div>
    );
}

export default MyNumberRedirect;
